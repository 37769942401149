<template>
  <div class="flex flex-row flex-wrap justify-center gap-x-1">
    <input
      v-for="index in Math.max(1, digits)"
      maxLength="1"
      :class="[context.classes.digit, index === 3 ? 'mr-4' : 'mr-0']"
      :value="tmp[index - 1] || ''"
      type="number"
      @input="handleInput(index - 1, $event)"
      @focus="handleFocus"
      @paste="handlePaste"
    >
    <span class="mr-3" />
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  context: Object,
});

const digits = Number(props.context!.digits);
const tmp = ref(props.context!.value || '') as Ref<string>;

function handleInput(index: number, e: InputEvent): void {
  const prev = tmp.value;

  if (tmp.value.length <= index) {
    tmp.value = '' + tmp.value + (e.target as HTMLInputElement).value;
  } else {
    tmp.value =
      '' +
      tmp.value.substring(0, index) +
      (e.target as HTMLInputElement).value +
      tmp.value.substring(index + 1);
  }

  const inputs = (e.target as HTMLInputElement).parentElement?.querySelectorAll('input');

  if (index < digits - 1 && tmp.value.length >= prev.length) {
    inputs?.item(index + 1)?.focus();
  } else if (index > 0 && tmp.value.length < prev.length) {
    inputs?.item(index - 1)?.focus();
  }

  if (tmp.value.length === digits) {
    props.context!.node.input(tmp.value);
  } else if (tmp.value.length < digits && props.context!.value !== '') {
    props.context!.node.input('');
  }
}

function handleFocus(e: FocusEvent): void {
  (e.target as HTMLInputElement).select();
}

function handlePaste(e: ClipboardEvent): void {
  const paste = e.clipboardData?.getData('text');
  if (typeof paste === 'string') {
    tmp.value = paste.substring(0, digits);
    const inputs = (e.target as HTMLInputElement).parentElement?.querySelectorAll('input');
    inputs?.item(tmp.value.length - 1)?.focus();
  }
}
</script>

<style scoped>
.formkit-digit {
  @apply h-16 w-12 border border-gray-300 border rounded-xl text-center text-lg;
}
</style>
