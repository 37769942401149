<template>
  <div>
    {{ error.statusCode }} - {{ error.message }}

    <button @click="handleError">
      Ga terug
    </button>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  error: {
    message: string
    statusCode: number
  },
}>();

useSeoMeta({
  title: props.error.message,
});

function handleError() {
  clearError({redirect: '/'});
}
</script>
