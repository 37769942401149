<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <UiNotification position="bottom center" />
</template>
<script setup lang="ts">
const route = useRoute();

useHead({
  title: () => route.meta.title,
});
</script>
