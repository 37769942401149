import revive_payload_client_lrGkc4rJSB from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_hi7slbb66s54hcmpb47nmdvkxe/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jiA52ixZIG from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_hi7slbb66s54hcmpb47nmdvkxe/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_DaoCOO4wx9 from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_hi7slbb66s54hcmpb47nmdvkxe/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_aR7XytLEkj from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/nuxt-site-config@2.2.12_@nuxt+devtools@1.3.1_@unocss+reset@0.60.2_change-case@4.1.2_floating-_qphawxdid54t6ksam3f2caw5ji/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.mjs";
import payload_client_U9mvUhjvt4 from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_hi7slbb66s54hcmpb47nmdvkxe/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_zjInEOI26m from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_hi7slbb66s54hcmpb47nmdvkxe/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/calculatietool/.nuxt/components.plugin.mjs";
import prefetch_client_P3grLTApFc from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_hi7slbb66s54hcmpb47nmdvkxe/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_6c0FxJI2s5 from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.22.7_rollup@4.17.2/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_lkJkOjxeyM from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.17.2_vue@3.4.27_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import formkitPlugin_pZqjah0RUG from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/calculatietool/.nuxt/formkitPlugin.mjs";
import plugin_vPBzCL8Ahx from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-module-apollo@1.0.1_graphql-ws@5.16.0_graphql@16.8.1__react-dom@18.2.0_react@_2aevgbdr2zbpzhz63iknqot26m/node_modules/@bttr-devs/nuxt-module-apollo/dist/runtime/plugin.mjs";
import plugin_AeEzcxqotL from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@_wmsitkh7id5qvy3agpvdhshsqi/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import chunk_reload_client_Vm0dQfaD4j from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@parcel+watcher@2.4.1_@types+node@20.12.12_@unocss+reset_hi7slbb66s54hcmpb47nmdvkxe/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import errors_c5QFun77pC from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@bugsnag+core@7.22.7_@nuxt+devtools@1.3.1_@unocss+reset@0.60_75ngenvetdolqzlprkrmbvsgdq/node_modules/@bttr-devs/nuxt-layer-base/plugins/errors.ts";
import i18n_IStQNKedi1 from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-base@1.0.2_@bugsnag+core@7.22.7_@nuxt+devtools@1.3.1_@unocss+reset@0.60_75ngenvetdolqzlprkrmbvsgdq/node_modules/@bttr-devs/nuxt-layer-base/plugins/i18n.ts";
import apollo_FhIukH8XJE from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/node_modules/.pnpm/@bttr-devs+nuxt-layer-sofie@1.0.2_@bugsnag+core@7.22.7_@nuxt+devtools@1.3.1_@unocss+reset@0.6_rreswyzc67z2oom3ndgmtjxwkm/node_modules/@bttr-devs/nuxt-layer-sofie/plugins/apollo.ts";
import i18n_DegFSxvh3m from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/core/plugins/i18n.ts";
import notifications_sUFtzNS9eT from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/core/plugins/notifications.ts";
export default [
  revive_payload_client_lrGkc4rJSB,
  unhead_jiA52ixZIG,
  router_DaoCOO4wx9,
  _0_siteConfig_aR7XytLEkj,
  payload_client_U9mvUhjvt4,
  check_outdated_build_client_zjInEOI26m,
  components_plugin_KR1HBZs4kY,
  prefetch_client_P3grLTApFc,
  plugin_6c0FxJI2s5,
  i18n_lkJkOjxeyM,
  formkitPlugin_pZqjah0RUG,
  plugin_vPBzCL8Ahx,
  plugin_AeEzcxqotL,
  chunk_reload_client_Vm0dQfaD4j,
  errors_c5QFun77pC,
  i18n_IStQNKedi1,
  apollo_FhIukH8XJE,
  i18n_DegFSxvh3m,
  notifications_sUFtzNS9eT
]