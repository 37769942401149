import {nl} from '@formkit/i18n';
import {plugin, createInput} from '@formkit/vue';
import FormkitOneTimePassword from '../core/components/formkit/OneTimePassword.vue';
import theme from './formkit.theme';
import {createFloatingLabelsPlugin} from '@formkit/addons';
import '@formkit/addons/css/floatingLabels';

export default {
  plugin,
  locales: {nl},
  locale: 'nl',
  inputs: {
    otp: createInput(FormkitOneTimePassword, {
      props: ['digits'],
    }),
  },
  icons: {
    search:`
      <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
        <path d="M15.875 14.6562L12.0938 10.875C12 10.8125 11.9062 10.75 11.8125 10.75H11.4062C12.375 9.625 13 8.125 13 6.5C13 2.9375 10.0625 0 6.5 0C2.90625 0 0 2.9375 0 6.5C0 10.0938 2.90625 13 6.5 13C8.125 13 9.59375 12.4062 10.75 11.4375V11.8438C10.75 11.9375 10.7812 12.0312 10.8438 12.125L14.625 15.9062C14.7812 16.0625 15.0312 16.0625 15.1562 15.9062L15.875 15.1875C16.0312 15.0625 16.0312 14.8125 15.875 14.6562ZM6.5 11.5C3.71875 11.5 1.5 9.28125 1.5 6.5C1.5 3.75 3.71875 1.5 6.5 1.5C9.25 1.5 11.5 3.75 11.5 6.5C11.5 9.28125 9.25 11.5 6.5 11.5Z" fill="#CCCCCC"/>
      </svg>
    `,
    eye: `
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12" fill="currentColor">
        <path d="M9 2.5C8.71875 2.53125 8.28125 2.59375 8 2.65625C8.125 2.875 8.21875 3.28125 8.25 3.5C8.25 4.46875 7.4375 5.25 6.5 5.25C6.25 5.25 5.84375 5.15625 5.65625 5.03125C5.5625 5.3125 5.5 5.71875 5.5 6C5.5 7.9375 7.0625 9.5 9 9.5C10.9375 9.5 12.5 7.9375 12.5 6C12.5 4.09375 10.9375 2.5 9 2.5ZM17.875 5.5625C16.1875 2.25 12.8125 0 9 0C5.15625 0 1.78125 2.25 0.09375 5.5625C0.03125 5.6875 0 5.875 0 6.03125C0 6.15625 0.03125 6.34375 0.09375 6.46875C1.78125 9.78125 5.15625 12 9 12C12.8125 12 16.1875 9.78125 17.875 6.46875C17.9375 6.34375 17.9688 6.15625 17.9688 6C17.9688 5.875 17.9375 5.6875 17.875 5.5625ZM9 10.5C5.90625 10.5 3.0625 8.78125 1.5625 6C3.0625 3.21875 5.90625 1.5 9 1.5C12.0625 1.5 14.9062 3.21875 16.4062 6C14.9062 8.78125 12.0625 10.5 9 10.5Z" fill="#CCCCCC"/>
      </svg>`,
    minus: `
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <path d="M17.5 11.087H6.5C6.21875 11.087 6 11.337 6 11.587V12.587C6 12.8683 6.21875 13.087 6.5 13.087H17.5C17.75 13.087 18 12.8683 18 12.587V11.587C18 11.337 17.75 11.087 17.5 11.087Z" fill="#CCCCCC"/>
    </svg>
    `,
    plus: `
      <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
        <path d="M12 5.5H7.5V1C7.5 0.75 7.25 0.5 7 0.5H6C5.71875 0.5 5.5 0.75 5.5 1V5.5H1C0.71875 5.5 0.5 5.75 0.5 6V7C0.5 7.28125 0.71875 7.5 1 7.5H5.5V12C5.5 12.2812 5.71875 12.5 6 12.5H7C7.25 12.5 7.5 12.2812 7.5 12V7.5H12C12.25 7.5 12.5 7.28125 12.5 7V6C12.5 5.75 12.25 5.5 12 5.5Z" fill="#CCCCCC"/>
      </svg>
    `,
    select: `
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" fill="none">
       <path d="M5.71875 5.875C5.875 6.03125 6.09375 6.03125 6.25 5.875L10.875 1.28125C11.0312 1.15625 11.0312 0.90625 10.875 0.75L10.25 0.15625C10.125 0 9.875 0 9.71875 0.15625L6 3.84375L2.25 0.15625C2.09375 0 1.875 0 1.71875 0.15625L1.09375 0.75C0.9375 0.90625 0.9375 1.15625 1.09375 1.28125L5.71875 5.875Z" fill="#757575"/>
    </svg>
    `,
  },
  config: {
    classes: theme,
  },
  plugins: [
    createFloatingLabelsPlugin({
      useAsDefault: true,
    }),
  ],
};
