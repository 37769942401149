import { default as calculatenBdF8xrUIIMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/calculatietool/pages/calculate.vue?macro=true";
import { default as indexKqTfdPnhqgMeta } from "/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/calculatietool/pages/index.vue?macro=true";
export default [
  {
    name: calculatenBdF8xrUIIMeta?.name ?? "calculate",
    path: calculatenBdF8xrUIIMeta?.path ?? "/calculate",
    meta: calculatenBdF8xrUIIMeta || {},
    alias: calculatenBdF8xrUIIMeta?.alias || [],
    redirect: calculatenBdF8xrUIIMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/calculatietool/pages/calculate.vue").then(m => m.default || m)
  },
  {
    name: indexKqTfdPnhqgMeta?.name ?? "index",
    path: indexKqTfdPnhqgMeta?.path ?? "/",
    meta: indexKqTfdPnhqgMeta || {},
    alias: indexKqTfdPnhqgMeta?.alias || [],
    redirect: indexKqTfdPnhqgMeta?.redirect,
    component: () => import("/data/www/internet.jaarbeurs.nl/jaarbeurs/releases/107/nuxt/calculatietool/pages/index.vue").then(m => m.default || m)
  }
]