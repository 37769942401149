<template>
  <Notifications
    :position="position"
    class="mb-5"
  >
    <template #body="{item, close}">
      <div
        class="mb-2.5 cursor-pointer rounded-lg p-5 text-sm"
        :class="notificationStyles(item)"
        @click="close()"
      >
        <div class="flex">
          <UiIcon
            v-if="item.type === undefined"
            name="fa-regular:check-circle"
            class="mr-5 text-[#009F38]"
          />

          <UiIcon
            v-if="item.type === 'error'"
            name="fa-regular:times-circle"
            class="text-primary-400 mr-5"
          />
          <div>
            <p class="m-0 font-semibold">
              {{ item.title }}
            </p>
            <div v-text="item.text" />
          </div>
        </div>
      </div>
    </template>
  </Notifications>
</template>
<script setup lang="ts">
defineProps<{
  position: string
}>();

function notificationStyles(item: {type?: string}) {
  return {
    'bg-paleRobinEggBlue text-darkSeaGreen': item.type === undefined,
    'bg-[#FFDBDB] text-[#B50009]': item.type === 'error',
  };
}
</script>
