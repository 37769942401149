import {generateClasses} from '@formkit/themes';

export default generateClasses({
  global: {
    fieldset: 'max-w-md rounded px-2 pb-1',
    help: 'text-xs text-gray-500',
    inner: 'formkit-disabled:bg-gray-300 formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none rounded-md placeholder:text-gray-300 bg-white border border-gray-200 focus-within:!border-primary hover:border-gray-300 transition duration-150',
    input: 'appearance-none rounded-md bg-transparent focus:outline-none ring-0 focus:shadow-none peer placeholder:text-gray-300',
    label: 'font-base block rounded-md w-fit !text-gray peer-focus:!leading-none data-[has-value="true"]:leading-none',
    legend: 'font-bold text-sm',
    loaderIcon: 'inline-flex items-center w-4 text-gray-600 animate-spin',
    message: 'text-red-500 mb-1 text-xs',
    messages: 'list-none p-0 mt-1 mb-0',
    outer: 'mb-4 formkit-disabled:opacity-50',
    prefixIcon: 'w-7 pl-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto bg-transparent',
    suffixIcon: 'w-7 mr-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto bg-transparent absolute right-0 h-full pointer-events-none',
  },
  'family:box': {
    label: '$reset text-sm accent-primary',
    inner: '$reset accent-primary',
    input: '$reset h-5 w-5 border border-gray-300 accent-primary-600',
    wrapper: '$reset flex gap-4 items-center',
  },
  'family:button': {
    input: '$reset inline-flex items-center justify-center bg-primary text-white font-normal py-3 px-5 rounded-sm hover:bg-primary-700 hover:border-primary-700 focus-visible:outline-2 focus-visible:outline-primary focus-visible:outline-offset-2 formkit-disabled:bg-gray-600 formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin',
    wrapper: 'mb-1',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
  },
  'family:dropdown': {
    dropdownWrapper: 'my-2 w-full drop-shadow-lg rounded [&::-webkit-scrollbar]:hidden',
    emptyMessageInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-gray-500 [&>span]:mr-3 [&>span]:ml-0',
    inner: 'max-w-md relative flex rounded mb-1',
    input: 'w-full px-3 py-2',
    listbox: 'bg-white drop-shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem: 'pl-7 relative hover:bg-gray-500 data-[is-active="true"]:bg-gray-500 data-[is-active="true"]:aria-selected:bg-blue-600 aria-selected:bg-blue-600 aria-selected:text-white',
    loaderIcon: 'ml-auto',
    loadMoreInner: 'flex items-center justify-center text-sm p-2 text-center w-full text-blue-500 formkit-loading:text-gray-500 cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
    option: 'p-2.5',
    optionLoading: 'text-gray-500',
    placeholder: 'p-2.5 text-gray-600',
    selector: 'flex w-full justify-between items-center [&u]',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0',
  },
  'family:text': {
    wrapper: 'relative',
    inner: 'flex items-center max-w-md rounded-md mb-1',
    input: 'w-full px-3 py-3 text-small',
    label: 'cursor-text left-0 block px-0.5 ml-1',
  },
  radio: {
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]',
  },
  select: {
    label: 'text-xs -translate-y-1/2 absolute left-2.5 z-10 px-1 py-0.5 bg-white',
    inner: 'flex relative max-w-md items-center rounded mb-1',
    input: 'w-full pl-3 pr-8 py-3 text-base text-gray-800 placeholder-gray-600 formkit-multiple:p-0 data-[placeholder="true"]:text-gray-600 formkit-multiple:data-[placeholder="true"]:text-inherit',
    selectIcon: 'flex p-[3px] shrink-0 w-5 mr-2 -ml-[1.5em] h-full pointer-events-none',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm text-gray-800',
    wrapper: 'relative',
  },
  textarea: {
    inner: 'flex max-w-md rounded mb-1',
    input: 'block w-full h-32 px-3 py-3 text-base text-gray-800 placeholder-gray-600 focus:shadow-outline',
  },
});
